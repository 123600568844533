<template>
  <div class="photo-wall single-media-wall">
    <div class="wall-content" :style="showInline ? 'display: flex;' : ''">
      <!-- 判断是否用按钮样式 -->
      <template
        v-if="
          isButtonStyle &&
          !useDefaultAvatar &&
          (!MediaData || JSON.stringify(MediaData) === '{}')
        "
      >
        <el-button
          :disabled="disabled"
          type="primary"
          @click="
            !disabled || JSON.stringify(MediaData) === '{}' ? (show = true) : ''
          "
          >{{ addText }}</el-button
        >
      </template>
      <div
        v-else
        class="img-content"
        :class="disabled ? 'disabled-hover' : 'no-disabled-hover'"
        :style="{
          width: widthSize,
          height: heightSize,
          'border-radius': BorderRadius,
          border:
            (!MediaData || JSON.stringify(MediaData) === '{}') &&
            !useDefaultAvatar
              ? '1px dashed #dcdfe6'
              : 'none',
          backgroundImage: useDefaultAvatar ? `url('${DefaultAvatar}')` : '',
        }"
        @click="
          !disabled || JSON.stringify(MediaData) === '{}' ? (show = true) : ''
        "
      >
        <div
          class="photo"
          v-if="UploadType === 'image' && MediaData"
          :style="{
            backgroundColor: transparent ? 'unset' : '',
          }"
        >
          <el-image
            class="photo"
            :style="{
              width: widthSize,
              height: heightSize,
              'border-radius': BorderRadius,
              backgroundColor: transparent ? 'unset' : '',
            }"
            :src="fixImageUrl(MediaData)"
            :fit="fit"
          />
          <div
            class="bg-mask"
            :style="{ 'line-height': heightSize }"
            v-if="showMask && !disabled"
            @click.stop.prevent
          >
            <p
              v-if="showCut"
              class="el-icon el-icon-scissors"
              @click.stop.prevent="handleCrop(MediaData)"
            ></p>
            <p
              v-if="showPreview"
              class="el-icon-zoom-in"
              @click.stop.prevent="handlePreview"
            ></p>
            <p
              v-if="showDelete"
              class="el-icon-delete"
              @click.stop.prevent="deleteMediaData"
            ></p>
            <p
              v-if="directly"
              class="el-icon-edit"
              @click.stop.prevent="show = true"
            ></p>
          </div>
        </div>
        <div
          v-if="UploadType === 'video' && JSON.stringify(MediaData) !== '{}'"
        >
          <el-image
            v-if="!isLive"
            class="photo"
            :style="{
              width: widthSize,
              height: heightSize,
              'border-radius': BorderRadius,
            }"
            :src="fixImageUrl(MediaData.thumbnail)"
            :fit="fit"
          ></el-image>
          <div class="video-mask">
            <p :style="{ 'line-height': heightSize }">
              <i
                @click.stop="handlePlay(MediaData)"
                class="el-icon-caret-right"
              ></i>
            </p>
          </div>
          <div
            v-if="!disabled"
            class="delete"
            @click.stop.prevent="deleteMediaData"
          >
            删除视频
          </div>
        </div>
        <div
          v-if="
            !useDefaultAvatar &&
            (!MediaData || JSON.stringify(MediaData) === '{}')
          "
          class="add"
        >
          <div class="add-content">
            <i
              class="el-icon-plus"
              :style="{
                fontSize: `${height / 2.5 > 25 ? 25 : height / 2.5}px`,
              }"
            ></i>
            <p class="info" v-if="addText">{{ addText }}</p>
          </div>
        </div>
      </div>

      <slot name="info"></slot>
    </div>
    <!-- 图片弹窗 -->
    <!-- <image-select v-model="show" :max="1" :upLoadType="UploadType" @handleChoose="handleMedia"/>-->
    <media-selector
      v-model="show"
      :type="UploadType"
      @select="handleMedia"
      :ratio="ratio"
    ></media-selector>
    <!--    <VideoDialog v-model="showVideo" :videoData="VideoData"/> -->
    <video-viewer />
  </div>
</template>
<script>
import MediaSelector from './MediaSelector'
import VideoViewer from '../../../base/components/Preview/VideoViewer'
import { randomString } from '../../../base/utils/tool'
import CropDialog from '../../../base/components/Media/CropDialog'
export default {
  name: 'SingleMediaWall',
  model: {
    prop: 'MediaData',
    event: 'handleMediaData',
  },
  provide() {
    return {
      mediaKey: this.mediaKey,
    }
  },
  props: {
    // 大小 px、%
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
    UploadType: {
      type: String,
      default: 'image',
    },
    // 图片src或视频object
    MediaData: {
      type: [String, Object],
      default: '',
    },
    // 图片如何适应容器框
    fit: {
      type: String,
      default: 'cover',
    },
    //添加按钮文字提示
    addText: {
      type: String,
      default: '上传图片',
    },
    //是否显示蒙层
    showMask: {
      type: Boolean,
      default: true,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 提示与图片是否换行显示
    showInline: {
      type: Boolean,
      default: false,
    },
    useDefaultAvatar: {
      type: Boolean,
      default: false,
    },
    /* 自定义默认图片，非必填 */
    avatarUrl: String,
    showDelete: {
      type: Boolean,
      default: true,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    showCut: {
      type: Boolean,
      default: false,
    },
    //内容发生变化的回调事件
    onChange: {
      type: Function,
      default: () => {},
    },
    /* 直接从媒体库选图，不需要先删再选 */
    directly: {
      type: Boolean,
      default: false,
    },
    transparent: Boolean,
    ratio: [String, Array],
    // 没图片的时候是否用按钮样式 （设计要加）
    isButtonStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mediaKey: randomString(),
      VideoData: {},
      show: false, //显示图片弹窗
      DefaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/images/wxapps/icons/avatar1.png', // 默认头像
    }
  },
  created() {
    if (this.avatarUrl) this.DefaultAvatar = this.avatarUrl
  },
  computed: {
    widthSize() {
      if (typeof this.width === 'number') {
        return this.width + 'px'
      } else {
        return this.width
      }
    },
    heightSize() {
      if (typeof this.height === 'number') {
        return this.height + 'px'
      } else {
        return this.height
      }
    },
    BorderRadius() {
      if (typeof this.borderRadius === 'number') {
        return this.borderRadius + 'px'
      } else {
        return this.borderRadius
      }
    },
    srcList() {
      return [this.MediaData]
    },
    isLive() {
      if (this.UploadType === 'video') {
        if (typeof this.MediaData === 'string') {
          if (this.MediaData.indexOf('.m3u8') !== -1) return true
        } else if (this.MediaData.url) {
          let hasLiveSuffix = this.MediaData.url.indexOf('.m3u8') !== -1
          if (hasLiveSuffix) return true
          //视频为空时清空
        } else if (
          this.MediaData.url === '' &&
          this.MediaData.thumbnail === ''
        ) {
          this.$emit('handleMediaData', {})
          this.onChange({})
        }
      }
      return false
    },
  },
  methods: {
    handleCrop(url) {
      if (typeof this.ratio === 'string') {
        this.ratioList = [this.ratio]
      } else if (this.ratio) {
        this.ratioList = this.ratio
      }
      // this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: url });
      this.$store.dispatch('crop/openMediaCropDialog', {
        imgSrc: [url],
        ratio: this.ratioList[0],
        ratioList: this.ratioList,
        mediaKey: this.mediaKey,
      })
    },
    // 确认
    handleMedia(mediaList) {
      if (this.UploadType === 'image') {
        const url = mediaList[0].url
        this.$emit('handleMediaData', url)
        this.onChange(url)
      } else {
        this.$emit('handleMediaData', {
          ...mediaList[0],
          ...mediaList[0].config,
          cover: mediaList[0]?.config?.thumbnail,
        })
        this.onChange({
          ...mediaList[0],
          ...mediaList[0].config,
          cover: mediaList[0]?.config?.thumbnail,
        })
      }
    },
    // 图片预览
    handlePreview() {
      this.$previewImage(this.srcList)
    },
    // 播放视频
    handlePlay(data) {
      this.$store.dispatch('media/openMediaVideoDialog', data)
    },
    //删除
    deleteMediaData() {
      if (this.UploadType === 'image') {
        this.$emit('handleMediaData', '')
        this.onChange('')
      } else {
        this.$emit('handleMediaData', {})
        this.onChange({})
      }
    },
  },
  components: {
    CropDialog,
    VideoViewer,
    MediaSelector,
  },
}
</script>
<style lang="scss" scoped>
.photo-wall {
  line-height: 1;

  &.is-error {
    .img-content {
      border: 1px dashed $danger !important;
    }
  }

  > .wall-content {
    // width: 100%;
    display: inline-block;

    .img-content {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      background-size: cover;

      .photo {
        vertical-align: middle;
        background-color: rgb(241, 241, 241);
      }

      .add,
      .bg-mask,
      .video-mask,
      .delete {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }

      //添加
      .add {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-content {
          font-size: 14px;
          line-height: 1;
          color: rgb(117, 117, 117);
          text-align: center;

          i {
            font-size: 25px;
          }

          .info {
            margin-top: 5px;
            color: inherit;
          }
        }
      }

      // 背景蒙层
      .bg-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: all 0.3s ease;

        p {
          font-size: 18px;
        }

        p + p {
          margin-left: 15px;
        }
      }

      .video-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.3);

        p {
          font-size: 30px;
        }
      }

      //删除
      .delete {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        transition: transform 0.2s ease-out;
        transform: translateY(30px);
      }
    }

    .disabled-hover {
      &:hover {
        cursor: not-allowed;
      }
    }

    .no-disabled-hover {
      &:hover {
        border-color: #3576ff !important;

        .add {
          .add-content {
            color: #3576ff;
          }
        }

        .bg-mask {
          opacity: 1;
        }

        .delete {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
